<template>
  <v-layout>
    <v-dialog 
      v-model="opened" 
      persistent
      :content-class="'j_modal ' + type"
    >
      <v-card>
        <v-card-title class="modal__header">
          <span>
            {{ formTitle }}
            <small v-if="modeNew">Create</small>
            <small v-else>{{ formSubTitle }}</small>
          </span>
          <j-button class="modal_close" @click="action('cancel')">
            <v-icon>mdi-close</v-icon>
          </j-button>
        </v-card-title>
        <v-card-text class="modal__contents">
          <v-form v-model="valid" ref="form">
            <slot></slot>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="!steppable">
          <v-spacer></v-spacer>
          <j-button v-if="modeNew && buttonInfo.create.visible" :disabled="!valid || !resetable" @click="action('create')" class="type01 sky" :class="{ disabled: !valid || !resetable }">Create</j-button>
          <j-button v-if="modeMod" :disabled="!valid || !resetable" @click="action('edit')" class="type01 sky" :class="{ disabled: !valid || !resetable }">{{ buttonInfo.edit.name }}</j-button>
          <j-button v-if="modeMod && buttonInfo.delete.visible" @click="action('delete')" class="type01 delete">Delete</j-button>
          <j-button @click="action('cancel')" class="type01">cancel</j-button>
        </v-card-actions>
        <v-card-actions v-else>
          <j-button v-if="modeMod" @click="action('delete')" class="type01 delete">Delete</j-button>
          <v-spacer></v-spacer>
          <j-button v-if="!stepFirst" @click="action('prev')" class="type01 sky">Previous</j-button>
          <j-button v-if="!stepLast" :disabled="this.stepInfo[this.stepper].stepValid || !satisfaction" @click="action('next')" class="type01 sky" :class="{ disabled: this.stepInfo[this.stepper].stepValid || !satisfaction }">Next</j-button>
          <j-button v-if="stepLast" :disabled="!valid || !resetable" @click="action('save')" class="type01 sky" :class="{ disabled: !valid || !resetable }">{{ buttonInfo.save.name }}</j-button>
          <j-button @click="action('cancel')" class="type01">cancel</j-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import _CONST_ from "@/primitives/_constant_"

export default {
  props: {
    buttonInfo: {
      type: Object,
      default: () => ({
        create: { name: 'Create', visible: true },
        delete: { name: 'Delete', visible: true },
        edit: { name: 'Edit', visible: true },
        cancel: { name: 'Cancel', visible: true },
        save: { name: 'Save', visible: true },
        prev: { name: 'Previous', visible: true },
        next: { name: 'Next', visible: true },
      })
    },
    formMode: String,
    opened: Boolean,
    resetable: Boolean,
    satisfaction: Boolean,
    steppable: Boolean,
    stepper: { type: Number, default: 0 },
    stepInfo: {
      type: Array,
      default: () => ([])
    },
    subTitle: { type: String, default: 'Modification' },
    title: { type: String, default: 'Jin Form Modal Window' },
    width: { type: Number, default: 300 },
    modalType: { type: String, default: "type01"}
  },
  data() {
    return {
      valid: false,
      type: this.modalType
    }
  },
  computed: {
    modeNew() { return this.formMode == _CONST_.FORM.EDIT_MODE_NEW || this.formMode == _CONST_.FORM.EDIT_MODE_ADD },
    modeMod() { return this.formMode == _CONST_.FORM.EDIT_MODE_MOD || this.formMode == _CONST_.FORM.EDIT_MODE_EDIT },
    formTitle() {
      if(this.steppable && this.stepInfo.length > this.stepper) return this.stepInfo[this.stepper].title
      else return this.title
    },
    formSubTitle() {
      if(this.steppable && this.stepInfo.length > this.stepper) return this.stepInfo[this.stepper].subTitle
      else return this.subTitle
    },
    steps() {
      return this.stepInfo.length
    },
    stepFirst() {
      return this.stepper === 0
    },
    stepLast() {
      return this.stepper === (this.steps - 1)
    }
  },
  methods: {
    action(type) {
      this.$emit(type)
    },
    formReset() {
      this.$refs.form.reset()
    },
    createDisabled() {
      return !this.valid
    }
  }
}
</script>
