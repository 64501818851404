<template>
  <div>
    <j-form-modal
      title="Data Configuration"
      sub-title="Form Value Controller"
      form-mode="Edit"
      @edit="onEdit()"
      @cancel="onCancel()"
      :button-info="buttonInfo"
      :modal-type="'type02'"
      :resetable="resetable"
      :opened.sync="opened"
    >
      <v-container class="j_column_control">
        <table class="j_column_control_thead">
          <thead>
            <tr>
              <th>Column Name</th>
              <th>Sortable</th>
              <th>Visible</th>
            </tr>
          </thead>
        </table>
        <div class="j_column_control_tbody">
          <table>
            <tbody>
              <tr v-for="(item, i) in itemsEditable" :key="i">
                <td>
                  <input 
                    v-model="selected" 
                    type="checkbox" 
                    :id="`_idg_service_config__checkbox_${i}__`" 
                    :value="i" 
                    @click="onCheckClick(i)"
                  />
                  <span
                    v-for="cDepth in Number(item.depth)"
                    class="prefix"
                    :class="treePrefix(item, cDepth, i)"
                    :key="cDepth"
                  >
                    <span></span>
                  </span>
                  <label :class="{ group: isGroupItem(item.type) }" :for="`_idg_service_config__checkbox_${i}__`">{{ item.text }}</label>
                </td>
                <td>
                  <div class="sortable_wrapper">
                    <div
                      v-ripple
                      class="sortable desc"
                      title="descending"
                      :class="{ selected : isSortable(item, 'desc') }"
                      @click="onSortOptionClick('desc', i)"
                    >
                      <v-icon :class="{ group: isGroupItem(item.type) }">mdi-arrow-down-bold</v-icon>
                    </div>
                    <div
                      v-ripple
                      class="sortable asc"
                      title="ascending"
                      :class="{ selected : isSortable(item, 'asc') }"
                      @click="onSortOptionClick('asc', i)"
                    >
                      <v-icon :class="{ group: isGroupItem(item.type) }">mdi-arrow-up-bold</v-icon>
                    </div>
                    <div class="sort-order">
                      <input v-if="displaySortOrder(item)" v-model="item.sortOrder" type="text" />
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    v-ripple
                    class="visible"
                    :class="{ selected : item.visible }"
                    @click="onVisibleClick(item, item.visible, i)"
                  >
                    <v-icon :class="{ group: isGroupItem(item.type) }">mdi-check-bold</v-icon>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="j_column_control_tfoot">
          <tfoot>
            <tr>
              <td>
                <input v-model="selectAll" id="_idg_service_config__checkbox_all__" type="checkbox" />
                <label for="_idg_service_config__checkbox_all__">Select All</label>
              </td>
              <!-- <td>
                <v-select
                  v-model="appliers.filterOption"
                  item-text="name"
                  item-value="code"
                  placeholder="Filterable"
                  content-class="single_select column_control"
                  :menu-props="{ closeOnContentClick: true }"
                  :items="filterOption"
                  @input="onApplySelectedAll('filterOption')"
                ></v-select>
              </td> -->
              <td>
                <v-select
                  v-model="appliers.sortOption"
                  item-text="name"
                  item-value="code"
                  placeholder="Sortable"
                  content-class="single_select column_control"
                  :items="[
                    { name:  'None', code: 'none' },
                    { name:  'ASC', code: 'asc' },
                    { name:  'DESC', code: 'desc' },
                  ]"
                  :menu-props="{ closeOnContentClick: true }"
                  @input="onApplySelectedAll('sortOption')"
                ></v-select>
              </td>
              <td>
                <v-select
                  v-model="appliers.visible"
                  item-text="name"
                  item-value="code"
                  placeholder="Visible"
                  content-class="single_select column_control"
                  :items="[
                    { name:  'Yes', code: true },
                    { name:  'No', code: false },
                  ]"
                  :menu-props="{ closeOnContentClick: true }"
                  @input="onApplySelectedAll('visible')"
                ></v-select>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-container>
    </j-form-modal>
  </div>
</template>

<script>
import * as d3 from 'd3'
import JFormModal from "@/components/floating/JFormModal"

export default {
  name: "service-intended-datagrid-config-modal",
  components: {
    JFormModal
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    buttonInfo: {
      create: { name: 'Create', visible: false },
      delete: { name: 'Delete', visible: false },
      edit: { name: 'Apply', visible: true },
      cancel: { name: 'Cancel', visible: true },
    },
    itemsEditable: [],
    selected: [],
    appliers: {},
    filterOption: [
      { name:  'None', code: 'none' },
      { name:  'Radio', code: 'radio' },
      { name:  'Check', code: 'check' },
      { name:  'Select', code: 'select' },
      { name:  'M-Select', code: 'mselect' },
    ]
  }),
  computed: {
    computedItems() {
      if (!this.items) return []
      return [...this.items]
    },
    computedEditableItems() {
      if (!this.itemsEditable) return []
      return [...this.itemsEditable]
    },
    opened: {
      get() { return this.value },
      set(val) { this.$emit("input", val) }
    },
    resetable() {
      if(!this.items) return
      return JSON.stringify(this.computedItems) != JSON.stringify(this.computedEditableItems)
    },
    selectAll: {
      get() { return this.itemsEditable ? this.selected.length == this.itemsEditable.length : false },
      set(val) {
        var selected = []

        if (val) {
          this.itemsEditable.forEach((t, i) => {
            selected.push(i)
          })
          this.selected = [...selected]

        } else {
          this.selected = []
        }
      }
    }
  },
  watch: {
    computedItems: {
      handler(val) {
        if(!val || val.length === 0) return
        this.init()
      },
      deep: true
    },
    opened(val) {
      if(!val) return
      this.init()
    }
  },
  methods: {
    init() {
      this.selected = []
      /* hard copy config values */
      this.itemsEditable = JSON.parse(JSON.stringify(this.computedItems))
      this.itemsEditable.forEach((item, i) => {
        if(item.type == 'group' && this.isChildAsserted(i, item.depth, 'visible')) {
          item.visible = true
        }
      })
    },
    isChildAsserted(cIndex, cDepth, applier) {
      let targetEndIndex = -1
      this.itemsEditable.find((item, i) =>
        item.depth <= cDepth && i > cIndex && (targetEndIndex = i)
      )
      if (targetEndIndex == -1) targetEndIndex = this.itemsEditable.length - 1

      let asserted = false
      let indices = [...Array(targetEndIndex - cIndex)].map((_, i) => i + cIndex)
      indices.forEach(targetIndex => {
        if(asserted === true) return
        if(applier == 'visible') asserted = this.itemsEditable[targetIndex][applier]
      })

      return asserted
    },
    isGroupItem(type) {
      return type == 'group'
    },
    isSortable(item, option) {
      if (item.sortable && item.sortOption == option) return true
      return false
    },
    onApplySelectedAll(applier) {
      if (this.appliers[applier] === '') return

      this.selected.forEach(i => {
        if(applier == 'filterOption' && this.isGroupItem(this.itemsEditable[i].type)) return

        this.itemsEditable[i][applier] = this.appliers[applier]

        if (applier == 'sortOption') {
          this.itemsEditable[i]['sortable'] = (this.appliers[applier] == 'none' ? false : true)
        } else if (applier == 'filterOption') {
          this.itemsEditable[i]['filterable'] = (this.appliers[applier] == 'none' ? false : true)
        }
      })

      setTimeout(() => { this.appliers[applier] = '' })
    },
    onCancel() {
      this.opened = false
    },
    onCheckClick(cIndex) {
      let cDepth = this.itemsEditable[cIndex]['depth']
      let type = this.itemsEditable[cIndex]['type']
      // At the time the check-box clicked, because of that the click event 
      // precedes than its status update, 'checked' status is still unchanged yet.
      // Hence it assumes that was changed contrary to real status. !()
      let checked = !(this.selected.indexOf(cIndex) > -1)

      if (type == 'group') {
        let targetEndIndex = -1
        this.itemsEditable.find((item, i) =>
          item.depth <= cDepth && i > cIndex && (targetEndIndex = i)
        )

        // no targetEndIndex found means that the clicked group is the last group.
        if (targetEndIndex == -1) targetEndIndex = this.itemsEditable.length

        let targetIndices = [...Array(targetEndIndex - cIndex)].map((_, i) => i + cIndex)

        if (checked) this.selected = this.selected.concat(targetIndices)
        else targetIndices.forEach(v => {
          let index = this.selected.indexOf(v)
          if (index > -1) this.selected.splice(index, 1)
        })

      } else {
        // gether the groups in one depth forward
        let groups = []
        this.itemsEditable.forEach((item, i) => {
          if (item.type == 'group' && item.depth <= cDepth - 1 && i < cIndex) groups.push(i)
        })

        if (groups.length > 0) {
          // start position
          let parentIndex = Math.max(...groups)

          /* gether the siblings or parent checked status */
          // get end position
          let targetEndIndex = -1
          this.itemsEditable.find((item, i) =>
            item.depth < cDepth && i > cIndex && (targetEndIndex = i)
          )
          if (targetEndIndex == -1) targetEndIndex = this.itemsEditable.length - 1

          // get siblings indices
          let siblingIndices = [...Array(targetEndIndex - parentIndex)].map((_, i) => i + parentIndex)
          // check siblings exist(checked)
          let siblingChecked = false
          siblingIndices.forEach(v => {
            // check other sibling(s) checked, except self index along with parent index
            if (![cIndex, parentIndex].includes(v) && this.selected.includes(v)) siblingChecked = true
          })

          if (checked) this.selected = this.selected.concat([parentIndex])
          else {
            if (!siblingChecked) {
              let index = this.selected.indexOf(parentIndex)
              if (index > -1) this.selected.splice(index, 1)
            }
          }
        }

        if (checked) this.selected = this.selected.concat([cIndex])
        else {
          let index = this.selected.indexOf(cIndex)
          if (index > -1) this.selected.splice(index, 1)
        }
      }
    },
    onEdit() {
      // build form of the valid filtered-values from the updated configProps
      let updatedFilterOptions = this.computedEditableItems.filter(
        item => item.filterable && item.filterOption != 'none'
      )

      let updatedFilteredValues = {}
      updatedFilterOptions.forEach(filter => {
        // filter.value : physical column name
        updatedFilteredValues[filter.value] = (
          ['check', 'mselect'].includes(filter.filterOption) ? [] : ''
        )
      })

      this.$emit('edit', {
        uFilteredValues: updatedFilteredValues,
        uConfigProps: JSON.parse(JSON.stringify(this.computedEditableItems))
      })
    },
    getChildrenIndices(parentIndex) {
      let cIndex = parentIndex // set current index (start point)
      let cDepth = this.itemsEditable[cIndex]['depth']
      let targetEndIndex = -1

      this.itemsEditable.find((item, i) =>
        item.depth <= cDepth && i > cIndex && (targetEndIndex = i)
      )
      // no targetEndIndex found means that the clicked group is the last group.
      if (targetEndIndex == -1) targetEndIndex = this.itemsEditable.length

      return [...Array(targetEndIndex - cIndex)].map((_, i) => i + cIndex)
    },
    onFilterOptionChanged(item, v, i) {
      if(this.isGroupItem(item.type)) {
        let childrenIndices = this.getChildrenIndices(i)

        childrenIndices.splice(childrenIndices.indexOf(i), 1)
        childrenIndices.forEach(indexValue => {
          if(indexValue != i) {
            let item = this.itemsEditable[indexValue]

            if(this.isGroupItem(item.type)) return

            item.filterOption = v
            this.onFilterOptionChanged(item, v)
          }
        })

        // init group item's value empty with little time-delay.
        setTimeout(() => { item.filterOption = '' })

      } else {
        if(v == 'none') item.filterable = false
        else item.filterable = true
      }
    },
    onSortOptionClick(option, i) {
      let items_ = JSON.parse(JSON.stringify(this.itemsEditable))

      if(this.isGroupItem(items_[i].type)) {
        let childrenIndices = this.getChildrenIndices(i)
        let option_ = 'none'
        let sortable_ = false

        childrenIndices.forEach(indexValue => {
          let item___ = items_[indexValue]

          if(indexValue == i && item___.sortOption != option) {
            option_ = option
            sortable_ = true
          }

          item___.sortOption = option_
          item___.sortable = sortable_
        })
      } else {
        if (items_[i].sortOption == option) {
          items_[i].sortOption = 'none'
          items_[i].sortable = false
        } else {
          items_[i].sortOption = option
          items_[i].sortable = true
        }
      }

      this.itemsEditable = items_
    },
    onVisibleClick(item, v, i) {
      if(this.isGroupItem(item.type)) this.getChildrenIndices(i).forEach(indexValue => {
        this.itemsEditable[indexValue]['visible'] = !v

      }); else item['visible'] = !v
    },

    displaySortOrder(item) {
      if(item.type == 'group' || !item.sortable) return false
      return true
    },
    setClassNone(id) {
      // new Promise((resolve) => {
      //   setTimeout(() => {
      //     let target = d3.select(`#${id}`).select('.v-select__selections').
      //     append('div').
      //     attr('class', 'v-select__selection v-select__selection--comma none').
      //     text('None')
      //     console.log(d3.select(`#${id}`).html())
      //   })
      // })
      return true
    },
    treePrefix(item, cDepth, cIndex) {
      // check self position/depth status
      if (Number(item.depth) == cDepth) {
        if (Number(item.siblings) > Number(item.siblingPosition)) return { with_sibling__has_child: true }
        return { no_sibling__has_child: true }

        // check parents (groups') position/depth status
      } else {
        // gether the groups in same depth to cDepth (current depth)
        let groups = [...this.itemsEditable].filter((item, i) =>
          item.type == 'group' && item.depth == cDepth && i < cIndex
        )
        if (groups.length == 0) return { no_sibling__no_child: true }

        let group = groups.pop()
        if (Number(group.siblings) > Number(group.siblingPosition)) return { with_sibling__no_child: true }

        return { no_sibling__no_child: true }
      }
    }
  }
}
</script>
